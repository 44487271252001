import React from 'react'
import appointment from '../../assets/images/new-images/Make Appointment -32x32.png'
import selectpkg   from '../../assets/images/new-images/Select Package 32x32.png'
import service from '../../assets/images/new-images/Get Your Service - 32x32.png'
import report from '../../assets/images/new-images/Report Project - 32x32.png'
import AppointmentFrom from '../AppointmentFrom'

function MakeAnAppointment() {
  return (
    <section id="about" className="about section appointment">
    <div className="container" data-aos="fade-up" data-aos-delay="100">
      <div className="row">
        <div className="col-xl-5 content">
        <AppointmentFrom/>
        </div>
        <div className="col-xl-7">
          <div className="row gy-4 icon-boxes">
            <h4>How It Works</h4>
            <h3>Making Luxury Car Servicing Easier for Customers!</h3>
            <p>We offer quality and convenience, together! Need our services? Just fill out this appointment form, and we will handle everything else.</p>
            <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div className="icon-box">
              <img src={appointment} alt="make-appoitment" />

                <h3>Make Appointment</h3>
                <p>Fill out the form, and we will note down your appointment while delivering the ease of pic-up and drop from your door-step.</p>
                <div className="text-center mt-0"><div className="buy-btn">STEPS 1</div></div>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div className="icon-box">
                <img src={selectpkg} alt="select-package" />
                <h3>Select Package</h3>
                <p>Select any package as per your budget and requirements.</p>
                <div className="text-center"><div  className="buy-btn">STEPS 2</div></div>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
              <div className="icon-box">
              <img src={service} alt="get-your-services" />

                <h3>Get Your Service</h3>
                <p>Our experts will reach out to you to deliver the service.</p>
                <div className="text-center"><div  className="buy-btn">STEPS 3</div></div>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div className="icon-box">
              <img src={report} alt="report-project" />

                <h3>Report Project</h3>
                <p>Share your honest feedback and help us improve!</p>
                <div className="text-center"><div  className="buy-btn">STEPS 4</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default MakeAnAppointment
