import React from 'react'
import { Link } from 'react-router-dom'
import AppointmentFrom from '../AppointmentFrom'

const ServicesSidebar = () => {
  return (
    <div>
          <div className="service-box">
                <div className="services-list">
                    <Link to="/repair-services">
                     Repair Services
                    </Link>
                    <Link to="/glamourous-details">
                    Glamourous Detailing
                    </Link>

                  
                 <Link to="/ceramic-coating">
                 Ceramic Coating
                 </Link>
                 <Link to="/ppf-services">
                 PPF Services
                 </Link>
                 <Link to="/master-dent-and-paint-solutions">
                 Master Dent & Paint Solutions
                 </Link>
                 <Link to="/periodic-maintenance">
                 Periodic Maintenance
                 </Link>
                 <Link to="/insurance-claim">
                 Insurance Claim
                 </Link>
                
                </div>
              </div>
              <div className="mt-5">
              <AppointmentFrom formId="appoitmen_form"/>
              </div>
    </div>
  )
}

export default ServicesSidebar