import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/Logo-01.jpg'
const Header = () => {
  const [isSidebarActive, setSidebarActive] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarActive(false);
    }
  };

  useEffect(() => {
    if (isSidebarActive) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarActive]);

  return (
    <>
      <header
        id="header"
        className="header d-flex align-items-center sticky-top"
      >
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-2">
              <Link
                to="/"
                className="logo d-flex align-items-center me-auto me-xl-0"
              >
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="col-lg-10">
              <nav id="navmenu" className="navbar navmenu navbar-expand-lg ">
                <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse d-none d-lg-block" id="navbarSupportedContent">
                  <ul className='navbar-nav ml-auto'>
                    <li>
                      <Link to="/" className="">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>

                    <li className="dropdown" >
                      <Link to="">
                        <span>Services</span>{" "}
                        <i className="bi bi-chevron-down toggle-dropdown"></i>
                      </Link>
                      <ul >
                        <li>
                          <Link to="/repair-services">Repair Services</Link>
                        </li>
                        <li>
                          <Link to="/glamourous-details">Glamourous Detailing</Link>
                        </li>
                        <li>
                          <Link to="/ceramic-coating">Ceramic Coating</Link>
                        </li>
                        <li>
                          <Link to="/ppf-services">PPF Services</Link>
                        </li>
                        <li>
                          <Link to="/master-dent-and-paint-solutions">
                            Master Dent & Paint Solutions
                          </Link>
                        </li>
                        <li>
                          <Link to="/periodic-maintenance">Periodic Maintenance</Link>
                        </li>
                        <li>
                          <Link to="/insurance-claim">Insurance Claim</Link>
                        </li>
                      </ul>
                    </li>

                    {/* <li>
                      <Link to="/">Blog</Link>
                    </li> */}
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </nav>
              <div ref={sidebarRef} className={`sidebar ${isSidebarActive ? 'active' : ''}`}>
                <ul className="nav flex-column">
                  <li className="nav-item"><Link className="nav-link" to="/" onClick={toggleSidebar}>Home</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="about" onClick={toggleSidebar}>About</Link></li>
                  <div class="dropdown p-0">
                    <button class="btn btn-secondary dropdown-toggle nav-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Services
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li className='dropdown-item'>
                        <Link to="/repair-services" onClick={toggleSidebar}>Repair  Services</Link>
                      </li>
                      <li className='dropdown-item'>
                        <Link to="/glamourous-details" onClick={toggleSidebar}>Glamourous Detailing</Link>
                      </li>
                      <li className='dropdown-item'>
                        <Link to="/ceramic-coating" onClick={toggleSidebar}>Ceramic Coating</Link>
                      </li>
                      <li className='dropdown-item'>
                        <Link to="/ppf-services" onClick={toggleSidebar}>PPF Services</Link>
                      </li>
                      <li className='dropdown-item'>
                        <Link to="/master-dent-and-paint-solutions" onClick={toggleSidebar}>
                          Master Dent & Paint Solutions
                        </Link>
                      </li>
                      <li className='dropdown-item'>
                        <Link to="/periodic-maintenance" onClick={toggleSidebar}>Periodic Maintenance</Link>
                      </li>
                      <li className="dropdown-item">
                          <Link onClick={toggleSidebar} to="/insurance-claim">Insurance Claim</Link>
                        </li>

                    </div>
                  </div>
                  <li className="nav-item">
                  <Link className="nav-link" to="/contact" onClick={toggleSidebar}>Contact</Link>
                  </li>
                 
                </ul>
              </div>
              <Link className="btn-getstarted f-right" to="https://calendly.com/inquiries-wrenchit/15-minutes-call" target="_blank">
                APPOINTMENT
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header