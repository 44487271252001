import React from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/images/Logo-01.jpg'

function Footer() {
  return (
    <footer id="footer" className="footer position-relative">
      {/* Top Section */}
      <div className="top-foot" style={{paddingBottom:"25px"}}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 ">
              <div className=" d-flex align-items-center info-cards p-4">
                <div className="footerIcons">
                  <i className="bi bi-geo-alt"></i>
                </div>
                <div
                  className="info-item d-flex flex-column"
                  data-aos="fade"
                  data-aos-delay="200"
                >
                  <h3 className="mb-0">Our Location</h3>
                  <p className="mb-0 mt-1">
                    <a target="_blank" href="https://www.google.com/maps?ll=18.574882,73.96097&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=7070497164024279247">
                    Gat no 1343 Ubale Nagar , Behind Bajaj Showroom Haveli ,
                    Pune Wagoli ( CT) 412 207
                    </a>
                
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className=" d-flex align-items-center p-4 info-cards">
                <div className="footerIcons">
                  <i className="bi bi-envelope"></i>
                </div>
                <div
                  className="info-item d-flex flex-column"
                  data-aos="fade"
                  data-aos-delay="200"
                >
                  <h3 className="mb-0">Email Us</h3>
                  <p className="mb-0 mt-1">
                    <a  href="mailto:info@wrenchit.co.in">
                    info@wrenchit.co.in
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className=" d-flex align-items-center p-4 info-cards">
                <div className="footerIcons">
                <i class="bi bi-phone"></i>
                </div>
                <div
                  className="info-item d-flex flex-column"
                  data-aos="fade"
                  data-aos-delay="200"
                >
                  <h3 className="mb-0">Phone Number</h3>
                  <p className="mb-0 mt-1">
                    <Link to="tel:+91 9096410050">+91 9096410050</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-5 col-md-12 footer-about">
          <Link
                to="/"
                className="logo d-flex align-items-center me-auto me-xl-0"
              >
                <img src={logo} alt="wrenchit-logo" />
              </Link>
            <h4 className="mb-0" style={{color : '#d5d4d4c2'}}>Redefining Luxury Automotive Services</h4>
            <h3 className="mb-5" style={{color : '#d5d4d4c2' , fontSize : '20px'}}>Delivering Excellence in Every Aspect of Car Care and Maintenance</h3>
           
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
               <Link to="/">
               Home
               </Link>
              </li>
              <li>
                <Link to="about">
                About
                </Link>
               
              </li>
              <li>
                <Link to="contact">
                Contact
                </Link>
               
              </li>
              
              {/* <li>
                <a href="#">Terms of service</a>
              </li> */}
              <li>
               <Link to="privacy-policy">
               Privacy Policy
               </Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li>
                <Link to="/repair-services">
                 Repair Services
                </Link>
              </li>
              <li>
                <Link to="glamourous-details">
                Glamourous Detailing
                </Link>
              </li>
              <li>
                      <Link to="/ceramic-coating">Ceramic Coating</Link>
                    </li>
                    <li>
                      <Link to="/ppf-services">PPF Services</Link>
                    </li>
                    <li>
                      <Link to="/master-dent-and-paint-solutions">
                        Master Dent & Paint Solutions
                      </Link>
                    </li>
                    <li>
                      <Link to="/periodic-maintenance">Periodic Maintenance</Link>
                    </li>
            
            </ul>
          </div>

          <div className="col-lg-3 col-md-12 footer-contact  text-md-start">
            <h4>
              Connect With Us
            </h4>
          <div className="social-links d-flex">
              <Link to="https://www.instagram.com/wrenchit_?igsh=Z3VzeG02d2NrMW94&utm_source=qr">
              <i className="bi bi-instagram"></i>
              </Link>
              
              <Link to="https://www.facebook.com/profile.php?id=61561159342438">
              <i className="bi bi-facebook"></i>
              </Link>
                <Link to="https://pin.it/4I67ieBkx">
                <i className="bi bi-pinterest"></i>
                </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="container copyright">
        <p>
          <span>Copyright</span> © 2024 <span>All Rights Reserved</span>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
