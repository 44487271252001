import React from 'react';

const ServicesSection = () => {
  return (
    <section id="services" className="services section">
      <div className="Benefits">
        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
          <h2>Services Benefits</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>
        {/* End Section Title */}

        <div className="container">
          <div className="row">

            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0"><i className="bi bi-briefcase"></i></div>
                <div>
                  <h4 className="title"><a href="services-details.html" className="stretched-link">Affordable Price</a></h4>
                  <p className="description">Lorem ipsum dolor sit amet, consectetur</p>
                </div>
              </div>
            </div>
            {/* End Service Item */}

            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0"><i className="bi bi-card-checklist"></i></div>
                <div>
                  <h4 className="title"><a href="services-details.html" className="stretched-link">Available 24 Hours</a></h4>
                  <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                </div>
              </div>
            </div>
            {/* End Service Item */}

            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0"><i className="bi bi-clock"></i></div>
                <div>
                  <h4 className="title"><a href="services-details.html" className="stretched-link">Experienced Mechanic</a></h4>
                  <p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                </div>
              </div>
            </div>
            {/* End Service Item */}

            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0"><i className="bi bi-clock"></i></div>
                <div>
                  <h4 className="title"><a href="services-details.html" className="stretched-link">Warranty Maintenance</a></h4>
                  <p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                </div>
              </div>
            </div>
            {/* End Service Item */}

          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
