import React from 'react'
import Banner from '../Components/service/Banner'
import ServiceDetails from '../Components/service/ServiceDetails'
import ServicesSection from '../Components/service/ServicesSection'
import BlogPostsSection from '../Components/service/BlogPostsSection'
const Services = () => {
  return (
    <main className='main'>
    <Banner />
    <ServiceDetails />
    <ServicesSection />
    <BlogPostsSection />
  </main>
  )
}

export default Services