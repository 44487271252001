import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import './GlamourPricing.css';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import PlanPicker from './DateAndTime/PlanPicker';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function GlamourPricing({formId}) {
  const [show, setShow] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    pickUpOrDropOff: 'default',
    address: '',
    selectedPlan: '', // Added selectedPlan to formData
    formId: '' // Add formId to formData
  });

  const handleClose = () => setShow(false);
  const handleShow = (plan ) => {
    setSelectedPlan(plan);
    setFormData(prevData => ({
      ...prevData,
      selectedPlan: plan, // Update selectedPlan in formData
     
    }));
    setShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData(prevData => ({
      ...prevData,
      date: date ? date.toISOString() : '',
    }));
  };

  const handlePickUpOrDropOffChange = (e) => {
    const value = e.target.value;
    setFormData(prevData => ({
      ...prevData,
      pickUpOrDropOff: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.pickUpOrDropOff === 'pick-up' && !formData.address) {
      alert('Address is required for pick-up.');
      return;
    }

    axios.post('https://wrenchit.co.in/plan-appointment.php', formData)
      .then((response) => {
        console.log('Form ID:', formData.formId);

        navigate('/thankyou'); // Redirect to the Thank You page
        // setResponseMessage('Thank you! Your message has been sent.');
        
        setFormData({
          name: '',
          email: '',
          phone: '',
          date: '',
          pickUpOrDropOff: 'default',
          address: '',
          selectedPlan: '', // Reset selectedPlan
          formId: formId // Reset formId
        });
        handleClose();
      })
      .catch((error) => {
        console.error('There was an error!', error);
        setResponseMessage('An error occurred. Please try again later.');
      });
  };

  return (
    <>
      <section id="pricing" className="pricing section pt-0">
        <div className="container section-title" data-aos="fade-up">
          <h4 className='text-black'>Choose A Package</h4>
          <h3 style={{ color: '#000', lineHeight: '40px', fontSize: '35px' }}>
            Pricing As Per Your Convenience
          </h3>
          <p className='text-center text-black mt-3'>
            We offer multiple pricing packages so that you can choose and pick the perfect one!
          </p>
        </div>

        <div className="container" data-aos="zoom-in" data-aos-delay="100">
          <div className="row g-5">
            {/* Add your plans here */}
            <div className="col-lg-4">
              <div className="pricing-item">
                <div className="price-head">
                  <h3>Exterior + Interior Regular Cleaning</h3>
                </div>
                <ul>
                  <li>Car Degreaser Wash</li>
                  <li>Car Shampoo</li>
                  <li>Alloy and Wheel Arches Wash</li>
                  <li>Glass Polish</li>
                  <li>Tire Dressing</li>
                  <li>Interior Vacuuming</li>
                  <li>Dashboard Polish</li>
                  <li>Car Perfume</li>
                </ul>
                <div className="text-center">
                  <Button onClick={() => handleShow('Exterior + Interior Regular Cleaning (Glamourous Services)')} className='buy-btn'>
                    Select Plan
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="pricing-item">
                <div className="price-head">
                  <h3>Exterior + Interior Deep Cleaning</h3>
                </div>
                <ul>
                  <li>Car Degreaser Wash</li>
                  <li>Car Shampoo</li>
                  <li>Alloy and Wheel Arches Wash</li>
                  <li>Glass Polish</li>
                  <li>Engine Dressing</li>
                  <li>Tire and Rubber Dressing</li>
                  <li>Interior Cleaning (including vacuuming, wiping, shampooing carpets, dashboard polish, and conditioning leather)</li>
                  <li>Car Perfume (premium fragrance options)</li>
                  <li>Wax Spray</li>
                </ul>
                <div className="text-center">
                  <Button onClick={() => handleShow('Exterior + Interior Deep Cleaning (Glamourous Services)')} className='buy-btn'>
                    Select Plan
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="pricing-item">
                <div className="price-head">
                  <h3>Wash & Wax Package</h3>
                </div>
                <ul>
                  <li>Car Degreaser Wash</li>
                  <li>Car Shampoo</li>
                  <li>Alloy and Wheel Arches Wash</li>
                  <li>Glass Polish</li>
                  <li>Tire Dressing</li>
                  <li>Interior Vacuuming</li>
                  <li>Dashboard Polish</li>
                  <li>Car Perfume</li>
                  <li>Machine Wax</li>
                </ul>
                <div className="text-center">
                  <Button onClick={() => handleShow('Wash & Wax Package (Glamourous Services)')} className=' btn buy-btn'>
                    Select Plan
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-md-5'>
            <div className="col-lg-4">
              <div className="pricing-item">
                <div className="price-head">
                  <h3>Wash + Polish + Wax Package</h3>
                </div>
                <ul>
                  <li>Car Degreaser Wash</li>
                  <li>Car Shampoo</li>
                  <li>Alloy and Wheel Arches Wash</li>
                  <li>Glass Polish</li>
                  <li>Tire Dressing</li>
                  <li>Interior Vacuuming</li>
                  <li>Dashboard Polish</li>
                  <li>Car Perfume</li>
                  <li>Machine Wax</li>
                  <li>Polish + Wax Sealant</li>
                </ul>
                <div className="text-center">
                  <Button onClick={() => handleShow('Wash + Polish + Wax Package (Glamourous Services)')} className='buy-btn'>
                    Select Plan
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-0">
              <div className="pricing-item">
                <div className="price-head">
                  <h3>Wash + Cutting + Polish + Wax Package</h3>
                </div>
                <ul>
                  <li>Car Degreaser Wash</li>
                  <li>Car Shampoo</li>
                  <li>Alloy and Wheel Arches Wash</li>
                  <li>Glass Polish</li>
                  <li>Tire Dressing</li>
                  <li>Interior Vacuuming</li>
                  <li>Dashboard Polish</li>
                  <li>Car Perfume</li>
                  <li>Machine Wax</li>
                  <li>Polish + Wax Sealant</li>
                  <li>Machine Wool and Pad</li>
                </ul>
                <div className="text-center">
                  <Button onClick={() => handleShow('Wash + Cutting + Polish + Wax Package (Glamourous Services)')} className='buy-btn'>
                    Select Plan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} className='modal-form'>
        <Modal.Header closeButton>
          <Modal.Title>{selectedPlan}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formPlan">
              <Form.Label>Selected Plan</Form.Label>
              <Form.Control type="text" value={selectedPlan} name="selectedPlan" readOnly />
            </Form.Group>
            <Row>
              <Col md={4} className='mt-3'>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4} className='mt-3'>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4} className='mt-3'>
                <Form.Group controlId="formPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    placeholder="Enter Mobile Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <Form.Label>Date and Time</Form.Label>
                <PlanPicker onDateChange={handleDateChange} />
              </Col>
            </Row>
            <Row className='mt-3 pricing_pickup'>
              <Col md={6}>
                <Form.Group controlId="formPickUpOrDropOff">
                  <Form.Label>Pick-up or Drop-off</Form.Label>
                  <Form.Select
                    name="pickUpOrDropOff"
                    value={formData.pickUpOrDropOff}
                    onChange={handlePickUpOrDropOffChange}
                    style={{
                      color: formData.pickUpOrDropOff === 'default' ? '#bdbdbd' : '#2d2a2a',
                    }}
                    required
                  >
                    <option value="default" disabled>Select Pickup/Drop-off</option>
                    <option value="pick-up">Pick-up</option>
                    <option value="drop-off">Drop-off</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Enter your address"
                    value={formData.address}
                    onChange={handleChange}
                    required={formData.pickUpOrDropOff === 'pick-up'}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className='text-center mt-3'>
              <Button type="submit" className='btn btn-success' style={{ margin: 'auto', padding: '10px 40px', textAlign: 'center' }}>
                Submit
              </Button>
            </div>
          </Form>
          {responseMessage && <p>{responseMessage}</p>}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GlamourPricing;
