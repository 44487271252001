import React from 'react';

const ServiceDetails = () => {
  return (
    <section id="service-details" className="service-details section">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="service-box">
              <div className="services-list">
                <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Exterior Detailing</span></a>
                <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Exterior Detailing</span></a>
                <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Product Management</span></a>
                <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Exterior Detailing</span></a>
                <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Marketing</span></a>
                <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Marketing</span></a>
              </div>
            </div>
            <div className="service-box contact-form">
              <h4>Make An Appointment</h4>
              <p>Lorem ipsum dolor sit amet</p>
              <div className="appoint-form">
                <form action="forms/contact.php" method="post" className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                  <div className="row gy-4">
                    <div className="col-md-12">
                      <input type="text" name="name" className="form-control" placeholder="Your Name" required />
                    </div>
                    <div className="col-md-12">
                      <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                    </div>
                    <div className="col-md-12">
                      <input type="text" className="form-control" name="subject" placeholder="Subject" required />
                    </div>
                    <div className="col-md-12">
                      <textarea className="form-control" name="message" rows="3" placeholder="Message" required></textarea>
                    </div>
                    <div className="col-md-12 text-center">
                      <button type="submit">Make Appointment</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-8 ps-lg-5 services-des" data-aos="fade-up" data-aos-delay="200">
            <h3>Exterior Detailing</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <img src="assets/img/services-first.png" alt="" className="img-fluid services-img" />
            <h3>Services Overview</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <div className="row">
              <div className="col-md-6">
                <h3>Common Issues</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magn.</p>
                <ul className="comman-is">
                  <li><i className="bi bi-check-circle"></i> <span>Morbi tristique senectus et netus</span></li>
                  <li><i className="bi bi-check-circle"></i> <span>Morbi tristique senectus et netus</span></li>
                  <li><i className="bi bi-check-circle"></i> <span>Morbi tristique senectus et netus</span></li>
                  <li><i className="bi bi-check-circle"></i> <span>Morbi tristique senectus et netus</span></li>
                  <li><i className="bi bi-check-circle"></i> <span>Ullamco laboris nisi ut aliquip ex ea</span></li>
                </ul>
              </div>
              <div className="col-md-6">
                <div className="comman-issue">
                  <img src="assets/img/2.png" alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceDetails;
