// src/components/TeamSection.jsx

import React from 'react';
import authorone from '../../assets/images/blog/blog-author-3.jpg';
import authortwo from '../../assets/images/blog/blog-author-4.jpg';
import authorthree from '../../assets/images/blog/blog-author-5.jpg';
import chahal from '../../assets/images/shweta-chahal.jpg';

const TeamSection = () => {
  return (
    <section id="team">
      <div className="container teamsec">
      <div className='text-center'>
      <h5>OUR TEAM</h5>
      <h3 className='font-weight-bold'><b>Meet Our Founders</b> </h3>
     
      </div>
       
        <div className="row mt-5">
          <div className='col-md-2'>
          <img src={chahal} className='img img-fluid' style={{width:'90%'}} alt="shweta-chahal" />

          </div>
          <div className="col-lg-10">
            <h4 className='mt-md-0 mt-lg-0 mt-4'>
            Shweta Chahal
            </h4>
            <p className='pb-0 '>
           
            <b>
            Co-Founder and Visionary Leader  
            </b>
            </p>
            <p className='pb-0 '>
            Shweta Chahal is the visionary behind Wrenchit. With a rich background in the luxury automotive industry and a profound passion for automotive services, she co-founded Wrenchit in 2024 to redefine the luxury car service industry. An alumna of the Indian School of Business,Hyderabad - Alma Matters. She aims to set new standards of automotive servicing and integrate cutting-edge technology for customer ease and transparency throughout the service lifecycle.

            </p>
            <p className='pb-0'>
            Beyond her role as Co-Founder, Shweta is deeply immersed in digital transformation, AI, and information technology. She leverages her extensive expertise in business excellence and strategic acumen to drive technological innovation, ensuring superior service and customer satisfaction. Her commitment to excellence and innovation positions Wrenchit at the forefront of the industry, delivering unparalleled value to its customers.
            </p>
          
          </div>
          
         
        </div>
      </div>
    </section>
  );
}

export default TeamSection;
