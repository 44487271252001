import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import PlanPicker from './DateAndTime/PlanPicker';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const PricingServices = ({formId}) => {
  const [show, setShow] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [pickUpOrDropOff, setPickUpOrDropOff] = useState('default');
  const [responseMessage, setResponseMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    pickUpOrDropOff: '',
    address: '',
    formId: formId, // Include the form ID in formData

  });

  const handleClose = () => setShow(false);
  const handleShow = (plan) => {
    setSelectedPlan(plan);
    setShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData(prevData => ({
      ...prevData,
      date: date ? date.toISOString() : '',
    }));
  };

  const handlePickUpOrDropOffChange = (e) => {
    const value = e.target.value;
    setPickUpOrDropOff(value);
    setFormData(prevData => ({
      ...prevData,
      pickUpOrDropOff: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (pickUpOrDropOff === 'pick-up' && !formData.address) {
      alert('Address is required for pick-up.');
      return;
    }
  
    const dataToSend = {
      ...formData,
      selectedPlan,
    };
  
    axios.post('https://wrenchit.co.in/plan-appointment.php', dataToSend)
      .then((response) => {
        console.log('Form ID:', formData.formId);
        // setResponseMessage('Thank you! Your message has been sent.');
        navigate('/thankyou'); // Redirect to the Thank You page
        setFormData({
          name: '',
          email: '',
          phone: '',
          date: '',
          pickUpOrDropOff: 'default',
          address: '',
          formId: formId, // Reset formId after submission

        });
        handleClose();
      })
      .catch((error) => {
        console.error('There was an error!', error);
        setResponseMessage('An error occurred. Please try again later.');
      });
  };

  return (
    
    <>
      <section id="pricing" className="pricing section pb-0 pb-lg-5" style={{ background: 'transparent' }}>
        <div className="container section-title" data-aos="fade-up">
          <h4 className='text-black'>Choose A Package</h4>
          <h3 style={{ color: '#000', lineHeight: '40px', fontSize: '35px' }}>Pricing As Per Your Convenience</h3>
          <p className='text-center text-black mt-3'>We offer multiple pricing packages so that you can choose and pick the perfect one!</p>
        </div>

        <div className="container" data-aos="zoom-in" data-aos-delay="100">
          <div className="row g-4">
            {[
              { name: 'Standard', duration: '3 Years', description: 'Protection against minor scratches and chips. High gloss and clarity with self-healing properties. Suitable for those seeking high-quality protection for shorter duration.' },
              { name: 'Premium', duration: '5 Years', description: 'Enhanced thickness for better protection against environmental damage. Advanced self-healing technology. Ideal for vehicle owners who want extended protection and durability.' },
              { name: 'Elite', duration: '8 Years', description: 'Superior protection with increased thickness and durability. Advanced UV protection to prevent yellowing and degradation. Perfect for luxury vehicle owners looking for top-tier protection and aesthetics.' },
              { name: 'Ultimate', duration: '10 Years', description: 'Maximum protection with the highest grade of PPF. Best-in-class self-healing properties and clarity. Designed for those who demand the ultimate in vehicle protection and appearance.' },
            ].map(plan => (
              <div className="col-lg-3" key={plan.name}>
                <div className="pricing-item">
                  <div className="price-head">
                    <h3>{plan.name}</h3>
                  </div>
                  <div className="price-amount">
                    <h4>
                      <sup>{plan.duration} <span> </span></sup>
                    </h4>
                  </div>
                  <ul>
                    {plan.description.split('. ').map((item, index) => <li key={index}>{item}</li>)}
                  </ul>
                  <div className="text-center">
                    <Button onClick={() => handleShow(plan.name)} className="buy-btn">Select Plan</Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} className='modal-form'>
        <Modal.Header closeButton>
          <Modal.Title>{selectedPlan}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formPlan">
              <Form.Label>Selected Plan</Form.Label>
              <Form.Control type="text" value={selectedPlan} readOnly />
            </Form.Group>
            <Row>
              <Col md={4} className='mt-3'>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4} className='mt-3'>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4} className='mt-3'>
                <Form.Group controlId="formPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    placeholder="Enter Mobile Number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col>
                <Form.Label>Date and Time</Form.Label>
                <PlanPicker onDateChange={handleDateChange} />
              </Col>
            </Row>
            <Row className='mt-3 pricing_pickup'>
              <Col md={6}>
                <Form.Group controlId="formPickUpOrDropOff">
                  <Form.Label>Pick-up or Drop-off</Form.Label>
                  <Form.Select
                    name="pickUpOrDropOff"
                    value={pickUpOrDropOff}
                    onChange={handlePickUpOrDropOffChange}
                    style={{
                      cssText: pickUpOrDropOff === 'default'
                        ? 'color: #bdbdbd !important'
                        : 'color: #2d2a2a !important',
                    }}
                    required
                  >
                    <option value="default" disabled>Select Pickup/Drop-off</option>
                    <option value="pick-up">Pick-up</option>
                    <option value="drop-off">Drop-off</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Enter your address"
                    value={formData.address}
                    onChange={handleChange}
                    required={pickUpOrDropOff === 'pick-up'}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className='text-center mt-3'>
              <Button type="submit" className='btn btn-success' style={{ margin: 'auto', padding: '10px 40px', textAlign: 'center' }}>
                Submit
              </Button>
            </div>
          </Form>
          {responseMessage && <p>{responseMessage}</p>}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PricingServices;
