import React from "react";

function Testimonials() {
  return (
    <section id="pricing" className="pricing section testimonials ">
      <div className="container section-title text-left" data-aos="fade-up">
        <h4 className="fw-normal pt-5" style={{
            color: "#32353a",

        }}>Testimonials</h4>
        <h3
          style={{
            color: "#32353a",
            lineHeight: "40px",
            fontSize: "35px",
            textAlign: "left",
          }}
        >
          What Our Customers Say?
        </h3>
        <p className="text-start" style={{width: '70%' , color :'#212529'}}>
        Read through some of the latest feedback we received from our customers. And yes, we are completely honest here!
        </p>
      </div>

      <div className="container" data-aos="zoom-in" data-aos-delay="100">
        <div className="row g-4">
          <div className="col-lg-4">
            <div className="pricing-item">
              <div className="stars">
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
              </div>
              <div className="price-head pb-1">
                <h3 className="" style={{textAlign :'left'}}>Sagar Babar</h3>
              </div>
              <div className="price-amount" style={{textAlign :'left'}}>
                <h4 className="fw-normal" style={{textAlign :'left'}}>Our Clients</h4>
              </div>
              <p>
              This is one of best Premier cars service centre. For me trust is everything  and  I can blindly trust Akshay with my Mercedes GLC . Very good service , on time . Keep it going .

              </p>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="pricing-item">
              <div className="stars">
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
              </div>
              <div className="price-head pb-1">
                <h3 style={{textAlign :'left'}}>Nitesh Sharma</h3>
              </div>
              <div className="price-amount" style={{textAlign :'left'}}>
                <h4 className="fw-normal">Our Clients</h4>
              </div>
              <p>
              I recently had my car serviced at Wrenchit, and I couldn't be more impressed! The team provided top-notch service, attention to detail, and exceptional customer care. My car feels brand new again. Highly recommend Wrenchit for all your automotive needs!

              </p>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="pricing-item">
              <div className="stars">
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
              </div>
              <div className="price-head pb-1">
                <h3 style={{textAlign :'left'}}>Anoop Kumar</h3>
              </div>
              <div className="price-amount" style={{textAlign :'left'}}>
                <h4 className="fw-normal">Our Clients</h4>
              </div>
              <p>
              I visited Wrenchit to do PPF on my car and it turned out so amazing! What impressed me most is their professionalism and and attention to detail. Their customer service could not have been more better with great scheduling and punctual updates. I highly recommend Wrenchit for top-notch PPF service, my car has never looked or been more protected better!
               </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
