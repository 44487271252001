import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bently from '../assets/images/car_logos/bently.png';
import jaguar from '../assets/images/car_logos/jaguar.jpg';
import landrover from '../assets/images/car_logos/landrover.png';
import lexus from '../assets/images/car_logos/lexus-logo-05-freelogovectors.net_.png';
import mini from '../assets/images/car_logos/mini.png';
import porche from '../assets/images/car_logos/porche.png';
import rollsroyce from '../assets/images/car_logos/rollsroyce.png';
import volvo from '../assets/images/car_logos/volvo-logo-vector.svg';
import bmw from '../assets/images/car_logos/bmw.png';
import merch from '../assets/images/car_logos/merch.png';
import range from '../assets/images/car_logos/range.png';
import audi from '../assets/images/car_logos/audi.png';
import ferrari from '../assets/images/car_logos/ferrari-logo.png';
import maybach from '../assets/images/car_logos/maybach.png';
import aston from '../assets/images/car_logos/aston-martin-logo.jpg';
import maserati from '../assets/images/car_logos/maserati.png';
import Lamborghini from '../assets/images/car_logos/Lamborghini-logo.jpg';

const CarLogo = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        arrows:false,
        slidesToShow: 7, // Adjust this based on how many logos you want to show at once
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024, // For screens less than 1024px wide
            settings: {
              slidesToShow: 2, // Show 2 slides at a time
              slidesToScroll: 1,
              dots: true
            }
          },
          {
            breakpoint: 600, // For screens less than 600px wide
            settings: {
              slidesToShow: 3, // Show 1 slide at a time
              slidesToScroll: 1,
          
              dots: false
            }
          }
        ]
      };
    
      const logos = [
        { src: bently, alt: "bently-logo" },
        { src: aston, alt: "aston-martin-logo" },
        { src: Lamborghini, alt: "lamborghini-logo" },
        { src: maserati, alt: "maserati-logo" },
        { src: bmw, alt: "bmw-logo" },
        { src: ferrari, alt: "ferrari-logo" },
        { src: jaguar, alt: "jaguar-logo" },
        { src: maybach, alt: "maybach-logo" },
        { src: rollsroyce, alt: "rolls-royce-logo" },
        { src: range, alt: "range-rover-logo" },
        { src: audi, alt: "audi-logo" },
        { src: merch, alt: "mercedes-benz-logo" },
        { src: volvo, alt: "volvo-logo" },
        { src: porche, alt: "porsche-logo" },
        { src: mini, alt: "mini-logo" },
        { src: lexus, alt: "lexus-logo" },
        { src: landrover, alt: "land-rover-logo" }
    ];
        
  return (
    <div className="logo-slider">
     <Slider {...settings}>
                {logos.map((logo, index) => (
                    <div key={index}>
                        <div className='card'>
                            <img src={logo.src} alt={logo.alt} />
                        </div>
                    </div>
                ))}
            </Slider>
  </div>
  )
}

export default CarLogo