import React from 'react'
import BlogPostsImage from '../Components/service/BlogPostsSection';

export default function AdvancedProtection() {
  return (
    <main className="main">

      {/* Page Title */}
      <div className="page-title services-banner" data-aos="fade">
        <div className="heading">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-8 main-banner">
                <h1 className="">Advanced Protection</h1>
                <p className="mb-0">Services -&gt; Advanced Protection</p>
              </div>
            </div>
          </div>
        </div>
        <nav className="secound-banner-sec">
          <div className="container">
            <div className="left-div">
              <p>We realize that you lead a busy life, so we have made it easy for you to</p>
              <h3>drop off your vehicle </h3>
            </div>
            <div className="Right-div">
              <a className="btn-getstarted f-right" href="contact.html">BOOK NOW</a>
              <img src="assets/img/key.png" alt="Key" />
            </div>
          </div>
        </nav>
      </div>
      {/* End Page Title */}

      {/* Service Details Section */}
      <section id="service-details" className="service-details section">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="service-box">
                <div className="services-list">
                  <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Exterior Detailing</span></a>
                  <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Exterior Detailing</span></a>
                  <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Product Management</span></a>
                  <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Exterior Detailing</span></a>
                  <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Marketing</span></a>
                  <a href="#"><i className="bi bi-arrow-right-circle"></i><span>Marketing</span></a>
                </div>
              </div>
              <div className="service-box contact-form">
                <h4>Make An Appointment</h4>
                <p>Lorem ipsum dolor sit amet</p>
                <div className="appoint-form">
                  <form action="forms/contact.php" method="post" className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                    <div className="row gy-4">
                      <div className="col-md-12">
                        <input type="text" name="name" className="form-control" placeholder="Your Name" required="" />
                      </div>
                      <div className="col-md-12 ">
                        <input type="email" className="form-control" name="email" placeholder="Your Email" required="" />
                      </div>
                      <div className="col-md-12">
                        <input type="text" className="form-control" name="subject" placeholder="Subject" required="" />
                      </div>
                      <div className="col-md-12">
                        <textarea className="form-control" name="message" rows="3" placeholder="Message" required=""></textarea>
                      </div>
                      <div className="col-md-12 text-center">
                        <button type="submit">make Appointment</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-8 ps-lg-5 services-des" data-aos="fade-up" data-aos-delay="200">
              <h3>Advanced Protection </h3>
              <p>You would always want to preserve the best look for your car, isn't it? We understand that! Our Advanced Protection service offers the ultimate care to safeguard and enhance your car's exterior. Our experts ensure that your car remains in pristine condition for years to come.</p>
              <img src="assets/img/services-first.png" alt="" className="img-fluid services-img" />
              <div className="row">
                <div className="col-md-6">
                  <h3>Why Wrenchit? </h3>
                  <p>With our state-of-the-art machinery and tried and tested process, you can trust that your vehicle will have seamless, full-coverage protection without compromising its appearance.</p>
                  <ul className="comman-is">
                    <li><i className="bi bi-check-circle"></i> <span>Skilled in the latest techniques</span></li>
                    <li><i className="bi bi-check-circle"></i> <span>Use only top-of-the-line products</span></li>
                    <li><i className="bi bi-check-circle"></i> <span>Meticulous attention to detail </span></li>
                    <li><i className="bi bi-check-circle"></i> <span>Your satisfaction is our top priority</span></li>
                    <li><i className="bi bi-check-circle"></i> <span>Unparalleled long-lasting quality </span></li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <div className="comman-issue">
                    <img src="assets/img/2.png" alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Service Details Section */}

      {/* Services Section */}
      <section id="services" className="services section">
        <div className="Benefits">
          <div className="container section-title" data-aos="fade-up">
            <h2>Our Services </h2>
            <p>At Wrenchit, we offer Paint Protection Film (PPF) and Ceramic Coating service to preserve the ever-lasting shine of your car. Durable PPF protects against scratches, chips, and environmental damage, preserving the original beauty of your car. Advanced ceramic coating provides a long-lasting, high-gloss finish that not only looks stunning but also offers superior protection.</p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0"><i className="bi bi-briefcase"></i></div>
                  <div>
                    <h4 className="title"><a href="services-details.html" className="stretched-link">Paint Protection Film</a></h4>
                    <p className="description">High-quality PPF acts as an invisible shield for your vehicle’s paintwork.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0"><i className="bi bi-card-checklist"></i></div>
                  <div>
                    <h4 className="title"><a href="services-details.html" className="stretched-link">Ceramic Coating </a></h4>
                    <p className="description">It creates a hydrophobic layer that repels water, dirt, and contaminants.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0"><i className="bi bi-clock"></i></div>
                  <div>
                    <h4 className="title"><a href="services-details.html" className="stretched-link">Chemical Resistance </a></h4>
                    <p className="description">Advanced ceramic coating protects your car against harsh chemical contaminants.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0"><i className="bi bi-clock"></i></div>
                  <div>
                    <h4 className="title"><a href="services-details.html" className="stretched-link">UV Protection </a></h4>
                    <p className="description">Our PPF prevents paint fading and oxidation under scorching sunlight and UV rays.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Services Section */}

      {/* Blog Posts Section */}
      <BlogPostsImage />
      {/* End Blog Posts Section */}

    </main>
  );
};
