import React from 'react'
import { Link } from 'react-router-dom'
const TopHeader = () => {
  return (
    <div>
          <div className="top-header">
        <div className="container align-itmes-end">
         
          <div className="social-med w-100">
            <ul className="social-icon text-end">
              <li>
              <Link to="tel:+919096410050">
                    <i className="bi bi-telephone primary-icon"></i>
                    +91 9096410050
                  </Link>
              </li>
              <li className="text-white">
                |
              </li>
              <li>
              <Link to="https://www.instagram.com/wrenchit_?igsh=Z3VzeG02d2NrMW94&utm_source=qr">
              <i className="bi bi-instagram"></i>
              </Link>
              
              </li>
              <li>
               
              <Link to="https://www.facebook.com/profile.php?id=61561159342438">
              <i className="bi bi-facebook"></i>
              </Link>
              
              </li>
              <li>
              <Link to="https://pin.it/4I67ieBkx">
                <i className="bi bi-pinterest"></i>
                </Link>
              </li>
             
            </ul>{" "}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopHeader