import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Components/HeaderFooter/Header'
import Footer from '../Components/HeaderFooter/Footer'
import TopHeader from '../Components/HeaderFooter/TopHeader'

function Layout() {
  return (
    <div>
      <TopHeader/>
        <Header />
        <Outlet/>
        <Footer/>
    </div>
  )
}

export default Layout
