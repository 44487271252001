import React from "react";
import key from "../assets/images/Key-up.png";
import ListItem from "../Components/ListItem/ListItem";
import serviceFirstImg from "../assets/images/services/essentia/Essential Repair Services 600x380.jpg";
import minor from "../assets/images/services/essentia/MINOR REPAIRS.png";
import ELECTRICAL from "../assets/images/services/essentia/ELECTRICAL ISSUES.png";
import FLUID from "../assets/images/services/essentia/FLUID LEVELS.png";
import WHEELS from "../assets/images/services/essentia/WHEELS AND TIRES.png";
import BRAKES from "../assets/images/services/essentia/BRAKES AND SUSPENSION.png";
import WINDSHIELD from "../assets/images/services/essentia/WINDSHIELD AND GLASS.png";
import EXHAUST from "../assets/images/services/essentia/EXHAUST SYSTEM.png";
import More from "../assets/images/services/essentia/MORE.png";
import MAJOR from "../assets/images/services/essentia/MAJOR REPAIRS.png";
import ENGINE from "../assets/images/services/essentia/ENGINE AND TRANSMISSION OVERHAUL.png";
import SUSPENSION from "../assets/images/services/essentia/SUSPENSION AND BRAKE SYSTEMS.png";
import COMPLEX from "../assets/images/services/essentia/COMPLEX ELECTRICAL FAULTS.png";
import BODYWORK from "../assets/images/services/essentia/BODYWORK AND STRUCTURAL DAMAGE.png";
import INTERIOR from "../assets/images/services/essentia/INTERIOR REFURBISHMENT.png";
import image2 from "../assets/images/services/essentia/Why Wrenchit 300x300.jpg";
import ServiceCardItem from "../Components/ServiceCardItem/ServiceCardItem";
import ServicesImages from "../Components/ServicesImages";
import { Helmet } from 'react-helmet-async';

import ServicesSidebar from "../Components/sidebar/ServicesSidebar";
import { Link } from "react-router-dom";

function EssentailServices() {
  const listItems = [
    "Certified and Experienced Technicians : Our team of skilled professionals ensures high-quality repairs for all vehicle makes and models.",
    "Comprehensive Services : From minor tweaks to major overhauls, we cover all aspects of vehicle repair.",
    "Convenient Scheduling Options : Flexible appointment times and doorstep services to fit your busy schedule.",
    "Customer Satisfaction Guaranteed : We prioritize your satisfaction with transparent communication and exceptional service.",
  ];

  const services = [
    {
      icon: "bi-briefcase",
      title: "Minor Repairs",
      description:
        "Our minor repair services cover a range of common issues to keep your vehicle running smoothly.",
      delay: 100,
      alt: "minor-repairs",
      image:minor
    },
    {
      icon: "bi-card-checklist",
      title: "Electrical Issues",
      description:
        "Diagnosis and repair of electrical problems, including faulty wiring, battery issues, and malfunctioning lights.",
      delay: 200,
      alt:"electrical-issues",
      image :ELECTRICAL

    },
    {
      icon: "bi-clock",
      title: "Fluid Levels",
      description:
        "Checking and topping up all essential fluids, such as coolant, brake fluid, transmission fluid, and power steering fluid.",
      delay: 300,
      alt:"fluid-levels",
      image :FLUID

    },
    {
      icon: "bi-clock",
      title: "Wheels and Tires",
      description:
        "Inspection and servicing of wheels and tires, including rotation, balancing, and alignment to ensure optimal performance and safety.",
      delay: 400,
      alt: "wheels-and-tires",
      image :WHEELS

    },
    {
      icon: "bi-clock",
      title: "Brakes and Suspension",
      description:
        "Adjusting and repairing brake and suspension systems to maintain safe and efficient braking and handling.",
      delay: 400,
      image :BRAKES

    },
    {
      icon: "bi-clock",
      title: "Windshield and Glass",
      description:
        "Repairing or replacing damaged windshields and windows to ensure clear visibility and safety.",
      delay: 400,
      image :WINDSHIELD

    },
    {
      icon: "bi-clock",
      title: "Exhaust System",
      description:
        "Addressing minor exhaust system issues to maintain proper emission levels and engine performance.",
      delay: 400,
      image :EXHAUST

    },
    {
      icon: "bi-clock",
      title: "More",
      description:
        "Handling a variety of other minor repairs to keep your vehicle in top shape.",
      delay: 400,
      image :More

    },
    {
      icon: "bi-clock",
      title: "Major Repairs",
      description:
        "For more significant issues, our major repair services are designed to restore your vehicle to its best condition.",
      delay: 400,
      image :MAJOR

    },
    {
      icon: "bi-clock",
      title: "Engine and Transmission Overhaul",
      description:
        "Overhauling or replacing engines and transmissions to ensure your vehicle runs efficiently and reliably.",
      delay: 400,
      image :ENGINE

    },
    {
      icon: "bi-clock",
      title: "Suspension and Brake Systems",
      description:
        "Comprehensive repair of suspension and brake systems to enhance safety and performance.",
      delay: 400,
      image :SUSPENSION

    },
    {
      icon: "bi-clock",
      title: "Complex Electrical Faults",
      description:
        "Resolving intricate electrical problems to ensure all vehicle systems function correctly.",
      delay: 400,
      image:COMPLEX
    },
    {
      icon: "bi-clock",
      title: "Bodywork and Structural Damage",
      description:
        "Repairing bodywork and structural damage to restore the integrity and appearance of your vehicle.",
      delay: 400,
      image :BODYWORK

    },
    {
      icon: "bi-clock",
      title: "Interior Refurbishment",
      description:
        "Refurbishing interiors, including upholstery repairs and replacement, to keep your vehicle looking and feeling new.",
      delay: 400,
      image :INTERIOR
    }
  ];
  services.forEach(service => {
    service.alt = service.title.toLowerCase().replace(/\s+/g, '-');
  });

  return (
    <main className="main">
        <Helmet>
        <title>Top Repair Services for Luxury Cars in Pune | Wrenchit</title>
        <meta
          name="description"
          content="Wrenchit offers professional luxury car repair services in Pune, from minor fixes to major overhauls. Trust our certified technicians for precision and reliability."
        />
        <link rel="canonical" href="https://wrenchit.co.in/repair-services" />

        "<meta property="og:title" content="Top Repair Services for Luxury Cars in Pune | Wrenchit" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="https://wrenchit.co.in/repair-services" />
        <meta property="og:description" content="Wrenchit offers professional luxury car repair services in Pune, from minor fixes to major overhauls. Trust our certified technicians for precision and reliability." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://wrenchit.co.in/static/media/Essential%20Repair%20Services%20600x380.4c20577fa8047aed297a.jpg" />
      </Helmet>
      {/* Page Title */}
      <div className="page-title services-banner" data-aos="fade">
        <div className="heading">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-8 main-banner">
                <h1 className="">Repair Services</h1>
                <p className="mb-0">Services &gt; Repair Services</p>
              </div>
            </div>
          </div>
        </div>
        <nav className="secound-banner-sec">
          <div className="container">
            <div className="left-div">
            <p>
                  {" "}
                  Get expert care for your luxury vehicle right at<br/> your doorstep.
                </p>
            </div>
            <div className="Right-div">
            <Link className="btn-getstarted f-right" to="/contact">
                  BOOK NOW
                </Link>
              <img src={key} alt="Key" />
            </div>
          </div>
        </nav>
      </div>
      {/* End Page Title */}

      {/* Service Details Section */}
      <section id="service-details" className="service-details section pb-0">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <ServicesSidebar/>
            </div>
            <div
              className="col-lg-8 ps-lg-5 services-des"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h3>Repair Services</h3>
              <p>
                At Wrenchit, we understand that Repair Services. are crucial
                for the safety and longevity of your vehicle. Whether it's minor
                fixes or major overhauls, our certified technicians are equipped
                to handle all your repair needs with precision and expertise.
                Our comprehensive repair services ensure your vehicle remains in
                top condition, providing you with peace of mind and a smooth
                driving experience.
              </p>
              <img
                src={serviceFirstImg}
                alt="repair-services"
                className="img-fluid services-img"
              />
              <div className="row why_wrenchit">
              <h3>Why Wrenchit?</h3>
                  <p className="mb-4">
                    Experience the Wrenchit difference with our unparalleled
                    expertise, convenience, and commitment to customer
                    satisfaction.
                  </p>
                <div className="col-md-6">
                 
                  <ul className="comman-is  mb-5">
                    {listItems.map((item, index) => (
                      <ListItem key={index} text={item} />
                    ))}
                  </ul>
                </div>
                <div className="col-md-6">
                  <div className="comman-issue">
                    <img src={image2} alt="why-wrenchit" className="img-fluid pt-0" />
                  </div>
                </div>
              </div>
          
            </div>
            {/* Services Section */}
          <main id="services" className="services section1 mt-0">
                <div className="Benefits BenefitsSection  px-12">
                  <div className="container p-0 mb-4" data-aos="fade-up">
                    <h3>Our Repair Services</h3>
                    <p>
                      Whether it’s a minor fix or a major overhaul, our
                      certified technicians provide reliable and thorough
                      repairs, giving you the confidence to drive worry-free.
                    </p>
                  </div>
                  <div className="container p-0">
                   
                    <div className="row g-4 essential_car_services">
                      {services.map((service, index , alt) => (
                        <ServiceCardItem
                          key={index}
                          alt={service.alt}
                          icon={service.icon}
                          title={service.title}
                          description={service.description}
                          delay={service.delay}
                          image={service.image}
                        />
                      ))}
                    </div>
                    
                  </div>
                  
                </div>
               <div className="container">
               <p className="mt-5 mb-0">
                  At Wrenchit, our Repair Services. services are designed to
                  address all your vehicle’s needs, ensuring it operates safely
                  and efficiently. Trust Wrenchit to keep your vehicle in prime
                  condition and experience the excellence of our service today.
                </p>
                {/* <ServicesImages/> */}
               </div>
              </main>
              {/* End Services Section */}
          </div>
        </div>
          
      </section>

      {/* End Service Details Section */}
      
    </main>
  );
}

export default EssentailServices;
