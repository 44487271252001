import React, { useState } from 'react';
import axios from 'axios';
import { Form, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ContactForm = ({formId}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: 'default',
    brand: 'default',
    message: '',
    formId:formId
  });

  const [responseMessage, setResponseMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('https://wrenchit.co.in/send-mail.php', formData)
      .then((response) => {
        // setResponseMessage('Thank you! Your message has been sent.');
        console.log('Form ID:', formData.formId);

        navigate('/thankyou'); // Redirect to the Thank You page

        // Clear the form fields
        setFormData({
          name: '',
          email: '',
          phone: '',
          service: 'default',
          brand: 'default',
          message: '',
          formId: formId, // Reset formId after submission

        });
      })
      .catch((error) => {
        setResponseMessage('An error occurred. Please try again later.');
      });
  };

  return (
    <form onSubmit={handleSubmit} className="php-email-form" data-aos="fade-up" data-aos-delay="200">
      <div className="row gy-4">
        <div className="col-md-6">
          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Your Name"
            required
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6">
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="Your Email"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <Col md={12}>
          <input
            type="tel"
            name="phone"
            className="form-control"
            placeholder="Enter Phone number"
            value={formData.phone}
            onChange={handleChange}
          />
        </Col>
        <Col md={12}>
          <Form.Group controlId="formService">
            <Form.Select
              name="service"
              value={formData.service}
              onChange={handleChange}
              style={{
                color: formData.service === 'default' ? '#bdbdbd' : '#ffffff',
                backgroundColor: '#212529',
              }}
              required
            >
              <option value="default" disabled>Select Services</option>
              <option>Repair Services</option>
              <option>Glamorous Detailing</option>
              <option>Ceramic Coating</option>
              <option>PPF Services</option>
              <option>Master Dent & Paint Solutions</option>
              <option>Periodic Maintenance</option>
              <option>Insurance Claim</option>
              <option>Others</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group controlId="formBrand">
            <Form.Select
              name="brand"
              value={formData.brand}
              onChange={handleChange}
              style={{
                color: formData.brand === 'default' ? '#bdbdbd' : '#ffffff',
                backgroundColor: '#212529',
              }}
              required
            >
              <option value="default" disabled>Select Brand</option>
              <option>Bentley</option>
              <option>Lamborghini</option>
              <option>Ferrari</option>
              <option>Jaguar</option>
              <option>Volvo</option>
              <option>Rolls Royce</option>
              <option>Range Rover</option>
              <option>Mercedes-Benz</option>
              <option>Audi</option>
              <option>Mini</option>
              <option>BMW</option>
              <option>Maybach</option>
              <option>Lexus</option>
              <option>Maserati</option>
              <option>Aston Martin</option>
              <option>Land Rover</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <div className="col-md-12">
          <textarea
            className="form-control"
            name="message"
            rows="3"
            placeholder="Your Message"
            required
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="col-md-12 text-center">
          <button type="submit">Send Message</button>
          {responseMessage && (
            <div className="response-message mt-2" style={{ color: '#fff' }}>
              {responseMessage}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
