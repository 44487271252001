// ContactSection.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';
const ContactSection = () => {
  return (
    <section id="contact" className="contact section">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-6">
          <ContactForm formId="contact_form"/>
          </div>
          <div className="col-lg-6">
            <div className="row contact-contact">
              <p>CONTACT</p>
              <h2>Get In Touch With Us!</h2>
              <p className="text-con">Avail the Wrenchit services and experience the difference! Our top-notch service comes with true expertise and dedication. Contact us today to schedule an appointment for doorstep car care service. Enjoy the convenience of free pick-up and drop off, along with a complimentary inspection for service and repairs.</p>
              <div className="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.9811916646618!2d73.95839497465337!3d18.574886667513933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c33c3b3be0d9%3A0x621f73b5a712f0cf!2sWrenchit!5e0!3m2!1sen!2sin!4v1721209179320!5m2!1sen!2sin" style={{width : '100%'}} height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>              </div>
              <div className="foo-contact">
                <div className="col-md-8 followus">
                  <h3>Follow Our Social Media Accounts</h3>
                </div>
                <div className="col-md-4 followus">
                  <div className="social-links d-flex mt-4">
                  <ul className='list-unstyled list-inline'>
                  <li className=' list-inline-item'>
              <Link to="https://www.instagram.com/wrenchit_?igsh=Z3VzeG02d2NrMW94&utm_source=qr">
              <i className="bi bi-instagram"></i>
              </Link>
              
              </li>
              <li className=' list-inline-item'>
               
              <Link to="https://www.facebook.com/profile.php?id=61561159342438">
              <i className="bi bi-facebook"></i>
              </Link>
              
              </li>
              <li className=' list-inline-item'>
              <Link to="https://pin.it/4I67ieBkx">
                <i className="bi bi-pinterest"></i>
                </Link>
              </li>
             
                  </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
