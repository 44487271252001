import React from 'react'
import './404.css'
import { Helmet } from 'react-helmet-async'
const PageNotFound = () => {

  return (
    <div>
<Helmet>
    <title>Page Not Found</title>
</Helmet>
<section className='pagenotfound '>
<div class="number">404</div>
<div class="text"><span>Ooops...</span><br/>page not found</div>
</section>
    </div>
  )
}

export default PageNotFound