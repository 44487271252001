import React from "react";
import serviceBanner from "../../assets/images/service-banner.png";

const Banner = () => {
  return (
    <div className="page-title services-banner" data-aos="fade">
      <div className="heading">
        <div className="container">
          <div className="row d-flex justify-content-center text-center">
            <div className="col-lg-8 main-banner">
              <h1 className=""> About Wrenchit </h1>
              <p className="mb-0">Services &gt; About Wrenchit </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
