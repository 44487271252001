import React, { useState, useRef, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Picker = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const datePickerRef = useRef(null);

  useEffect(() => {
    if (onDateChange) {
      onDateChange(startDate);
    }
  }, [startDate, onDateChange]);

  const handleDatePickerClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };

  return (
    <div onClick={handleDatePickerClick}>
      <ReactDatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          onDateChange(date); // Notify parent of date change
        }}
        showTimeSelect
        dateFormat="yyyy-MM-dd HH:mm"
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Time"
        placeholderText="Select Date and Time"
        className={`date-picker-appoitment ${startDate ? 'selected' : ''}`}
        ref={datePickerRef}
      />
    </div>
  );
};

export default Picker;
