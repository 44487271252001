import React from "react";
import whyChooseUsImg from "../../assets/images/new-images/certified-professionals.jpg";
import love from "../../assets/images/new-images/why_wrenchit/love.png";
import equipment from "../../assets/images/new-images/why_wrenchit/equipment.png";
import pricing from "../../assets/images/new-images/why_wrenchit/pricing.png";
import team from "../../assets/images/new-images/why_wrenchit/team.png";

function WhyChooseUs() {
  return (
    <section
      id="service-details"
      className="service-details section home-content whychoose"
  

    >
      <div className="container">
        <div className="row gy-5">
          <div
            className="col-lg-6 services-des why_choose"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h4 className="sub-heading-highlighted ">Why Wrenchit?</h4>
            <h3>Certified Professionals Delivering Award-Winning Services</h3>
            <p className="mb-0 whychoose-description">
            Our team comprises of seasoned experts with extensive work experience with brands like Mercedes-Benz Audi, BMW and Jaguar Land Rover.
            </p>
        
            <div className="service-item d-flex align-items-start mt-3">
              <div className="icon flex-shrink-0">
              <img src={team} alt="experienced-team" />
              </div>
              <div>
                <h4 className="why-choose-title">Experienced Team</h4>
                <p className="why-choose-description mb-0">
                  Our team comprises seasoned experts with extensive experience
                  of working for brands like Mercedes-Benz Audi, BMW and Jaguar
                  Range Rover.
                </p>
              </div>
            </div>

            <div className="service-item d-flex align-items-start border-require">
              <div className="icon flex-shrink-0">
              <img src={love} alt="loved-by-critics-and-customers" />
              </div>
              <div>
                <h4 className="why-choose-title">
                  Loved By Critics and Customers
                </h4>
                <p className="why-choose-description mb-0">
                  Our reviews and ratings reflect our commitment to excellence,
                  ensuring top-notch service every time.{" "}
                </p>
              </div>
            </div>

            <div className="service-item d-flex align-items-start ">
              <div className="icon flex-shrink-0">
              <img src={equipment} alt=" World-Class-equipment-and-results" />
              </div>
              <div>
                <h4 className="why-choose-title">
                  World-Class Equipment & Results
                </h4>
                <p className="why-choose-description mb-0">
                  Equipped with cutting-edge modern equipment, our experts
                  deliver the best results every time!
                </p>
              </div>
            </div>
            <div className="service-item d-flex align-items-start border-require">
              <div className="icon flex-shrink-0">
              <img src={pricing} alt="pricing-as-per-covenience" />
              </div>
              <div>
                <h4 className="why-choose-title">Pricing As Per Convenience</h4>
                <p className="why-choose-description mb-0">
                  We offer multiple pricing packages so that you can choose and
                  pick the perfect one!
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-6 mt-0 video-play-sec"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img
              src={whyChooseUsImg}
              alt="certified-professionals"
              className="img-fluid services-img"
            />
            {/* <div className="play-video">
              <h3>0 %</h3>
              <p>Client Satisfaction</p>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyChooseUs;
