import React from 'react';
import key from '../../assets/images/key.png'
const Banner = () => {
  return (
    <div className="page-title services-banner" data-aos="fade">
      <div className="heading">
        <div className="container">
          <div className="row d-flex justify-content-center text-center">
            <div className="col-lg-8 main-banner">
              <h1>Services Detail</h1>
              <p className="mb-0">Home -&gt; Services Details -&gt; Exterior Detailing</p>
            </div>
          </div>
        </div>
      </div>
      <nav className="secound-banner-sec">
        <div className="container">
          <div className="left-div">
            <p>We realize that you lead a busy life, so we have made it easy for you to</p>
            <h3>drop off your vehicle 24/7</h3>
          </div>
          <div className="Right-div">
            <a className="btn-getstarted f-right" href="index.html#about">BOOK NOW</a>
            <img src={key} alt="Key" />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Banner;
