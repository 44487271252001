import React from "react";
import key from "../assets/images/Key-up.png";
import ListItem from "../Components/ListItem/ListItem";
import serviceFirstImg from "../assets/images/services/coating/Ceramic Coating 600x380.jpg";
import image2 from "../assets/images/services/coating/Ceramic Coating why wrenchit  300x300.jpg";
import ServiceCardItem from "../Components/ServiceCardItem/ServiceCardItem";
import ServicesImages from "../Components/ServicesImages";
import ServicesSidebar from "../Components/sidebar/ServicesSidebar";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const CeramicCoating = () => {
  const listItems = [
    `Long-Lasting Protection: Options for 1, 2, 3, and 5-year durability to suit your needs.`,
    "Superior Gloss and Shine: Enhanced gloss and depth of color, making your car look showroom-new.",
    "Hydrophobic Properties: Water-repellent properties that make cleaning easier and keep your car looking cleaner for longer.",
    "UV Protection: Shielding your vehicle’s paint from harmful UV rays that cause fading and oxidation.",
    "Chemical Resistance: Protection against harsh chemicals, bird droppings, tree sap, and other environmental contaminants.",
    "Scratch Resistance: Increased resistance to minor scratches and swirl marks, maintaining the integrity of your vehicle’s paint.",
  ];
  const listItems2 = [
    "Thorough Washing: Comprehensive cleaning to remove dirt, grime, and contaminants.",
    "Decontamination: Claying and chemical decontamination to ensure a clean surface for coating application.",
    "Paint Correction: Polishing to remove swirl marks, scratches, and imperfections, providing a flawless base for the coating.",
  ];
  const listItems3 = [
    "High-Quality Coatings: Application of premium ceramic coatings designed for durability and protection.",
    "Multiple Layers: Application of multiple layers of coating for enhanced protection and longevity.",
    "Curing Process: Proper curing of the coating to ensure a strong bond with the vehicle’s surface.",
  ];
  const generateAltText = (text) => {
    return text.toLowerCase().replace(/ /g, "-");
  };
  return (
    <>
      <main className="main">
      <Helmet>
        <title>Ceramic Coating Services in Pune | Durable Protection by Wrenchit</title>
        <meta
          name="description"
          content="Protect and shine your vehicle with Wrenchit’s Ceramic Coating Services in Pune. Choose from 1 to 5 years of durable defense against environmental elements."
        />
        <link rel="canonical" href="https://wrenchit.co.in/ceramic-coating" />

        "<meta property="og:title" content="Ceramic Coating Services in Pune | Durable Protection by Wrenchit" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="https://wrenchit.co.in/ceramic-coating" />
        <meta property="og:description" content="Protect and shine your vehicle with Wrenchit’s Ceramic Coating Services in Pune. Choose from 1 to 5 years of durable defense against environmental elements." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://wrenchit.co.in/static/media/Glamourous%20Detailing%20600x380.fb851d50feb3177227a1.jpg" />
      </Helmet>
        {/* Page Title */}
        <div className="page-title services-banner" data-aos="fade">
          <div className="heading">
            <div className="container">
              <div className="row d-flex justify-content-center text-center">
                <div className="col-lg-8 main-banner">
                  <h1 className="">Ceramic Coating</h1>
                  <p className="mb-0">Services &gt; Ceramic Coating</p>
                </div>
              </div>
            </div>
          </div>
          <nav className="secound-banner-sec">
            <div className="container">
              <div className="left-div">
              <p>
                  {" "}
                  Get expert care for your luxury vehicle right at<br/> your doorstep.
                </p>
              </div>
              <div className="Right-div">
                <Link className="btn-getstarted f-right" to="/contact">
                  BOOK NOW
                </Link>
                <img src={key} alt="Key" />
              </div>
            </div>
          </nav>
        </div>
        {/* End Page Title */}

        {/* Service Details Section */}
        <section id="service-details" className="service-details section pb-0">
          <div className="container">
            <div className="row gy-5">
              <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                <ServicesSidebar/>
              </div>
              <div
                className="col-lg-8 ps-lg-5 services-des"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h3>Ceramic Coating</h3>
                <p>
                  Wrenchit offers top-of-the-line Ceramic Coating services to
                  provide your vehicle with long-lasting protection and a
                  stunning shine. With options for 1, 2, 3, and 5-year
                  durability, our ceramic coating ensures your car remains
                  shielded against environmental elements while maintaining its
                  pristine appearance.
                </p>
                <img
                  src={serviceFirstImg}
                  alt={generateAltText("Ceramic Coating Service")}
                  className="img-fluid services-img"
                />
                 <h3 className="mt-3">Why Wrenchit?</h3>
                    <p className="mb-4">
                      Choose Wrenchit for your ceramic coating needs and
                      experience unparalleled quality and service:
                    </p>
                <div className="row mt-3">
                  <div className="col-md-6">
                   
                    <ul className="comman-is w-100 mb-5 mb-lg-0">
                      {listItems.map((item, index) => (
                        <ListItem key={index} text={item} />
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <div className="comman-issue">
                      <img src={image2}  alt={generateAltText("Ceramic Coating Why Wrenchit")} className="img-fluid pt-0" />
                    </div>
                  </div>
                </div>
           
              </div>
                   {/* Services Section */}
                   <main id="services" className="services section1  mt-0">
                  <div className="Benefits BenefitsSection  px-12">
                    <div className="container p-0 mb-4" data-aos="fade-up">
                      <h3>Our Ceramic Coating Services</h3>
                      <p>
                        Our Ceramic Coating service involves a multi-step
                        process designed to protect and enhance your vehicle’s
                        exterior. The workflow for our ceramic coating service
                        is as follows:
                      </p>
                    </div>
                    <div className="container p-0 pt-4">
                      <h4 className="mb-2">Surface Preparation</h4>
                      <div className="row g-2">
                        {listItems2.map((item, index) => (
                          <ListItem key={index} text={item} noIcon={true} />
                        ))}
                      </div>
                    </div>
                    <div className="container p-0 py-4">
                      <h4 className="mb-2">Ceramic Coating Application</h4>

                      <div className="row g-2">
                        {listItems3.map((item, index) => (
                          <ListItem key={index} text={item} noIcon={true} />
                        ))}
                      </div>
                    </div>
                  </div>
                 
                  <p className="mt-5 mb-0 mx-0" style={{paddingLeft:'12px',paddingRight:"12px"}}>
                    Protect and enhance your car's look and shine with
                    Wrenchit’s Ceramic Coating service. Our expert technicians
                    use premium products and meticulous techniques to provide
                    long-lasting protection and a stunning shine. With options
                    for 1, 2, 3, and 5-year durability, you can choose the level
                    of protection that suits your needs. Experience the
                    difference with Wrenchit and keep your car looking its best
                    for years to come.
                  </p>
                  {/* <ServicesImages/> */}
                </main>
                {/* End Services Section */}
            </div>
          </div>
        </section>

        {/* End Service Details Section */}
      </main>
    </>
  );
};

export default CeramicCoating;


