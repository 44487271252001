import React from 'react';

const ServiceCardItem = ({ icon, title, image, description, delay , alt }) => {
  return (
    <div className="col-lg-6" data-aos="fade-up" data-aos-delay={delay}>
      <div className="service-item pt-0 d-flex">
        <div className="icon flex-shrink-0">
          <img src={image} alt={alt}/>
        </div>
        <div>
          <h4 className="title">{title}</h4>
          <p className="description">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCardItem;
