import React from 'react';

const ListItem = ({ text ,noIcon}) => (
  <li>
   
    <span>{text}</span>
  </li>
);

export default ListItem;
