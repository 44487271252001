import React from 'react'
import Banner from '../Components/about/Banner'
import Details from '../Components/about/Details'
import SpecialOffers from '../Components/about/SpecialOffers'
import TeamSection from '../Components/about/TeamSection'
import { Helmet } from 'react-helmet-async';

const About = () => {
  return (
    <div>
      <Helmet>
        <title>About Wrenchit | Premium Luxury Car Services in Pune</title>
        <meta
          name="description"
          content="Explore premium car services in Pune at Wrenchit, where we combine precision, innovation, and contemporary technology to elevate luxurious automotive care."
        />
        <link rel="canonical" href="https://wrenchit.co.in/about" />

        "<meta property="og:title" content="About Wrenchit | Premium Luxury Car Services in Pune" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="https://wrenchit.co.in/about" />
        <meta property="og:description" content="Explore premium car services in Pune at Wrenchit, where we combine precision, innovation, and contemporary technology to elevate luxurious automotive care." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://wrenchit.co.in/static/media/Delivering%20Excellence%20-%20700x930.4b27b504d3f814759931.jpg" />
      </Helmet>
      <main className='main'>
        <Banner />
        <Details />
        <SpecialOffers />
        <TeamSection />
      </main>
    </div>
  )
}

export default About