import React from "react";
import ServiceItem from "./ServiceItem";
import ServiceFirstImg from "../../assets/images/new-images/about-us.jpg";
import periodic from "../../assets/images/new-images/PERIODIC MAINTAINANCE 28X28.png";
import paint from "../../assets/images/new-images/PPF.png";
import ceramic from "../../assets/images/new-images/CERAMIC COATINGS 28x28.png";
import essential from "../../assets/images/essential.png";
import glamour from "../../assets/images/glamour.png";
import master from "../../assets/images/master.png";
import VideoPlayer from "./VideoComponent/VideoPlayer";
import { Link } from "react-router-dom";
function ServiceDetails() {
  return (
    <section
      id="service-details"
      className="service-details section home-content"
    >
      <div className="container">
        <div className="row gy-5">
          <div
            className="col-lg-6 video-play-sec "
            data-aos="fade-up"
            data-aos-delay="100"
          >
           
            <img
              src={ServiceFirstImg}
              alt="about-us"
              className="img-fluid services-img"
            />
            {/* <VideoPlayer/> */}
             {/* <div className="play-video">
              <i className="bi bi-youtube"></i> PLAY VIDEO
            </div> */}
          </div>

          <div
            className="col-lg-6 ps-lg-5 services-des "
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <small>About Us</small>
            <h3>Setting New Standards in Advanced Automotive Servicing </h3>
            <p>
            Wrenchit is set to redefine luxury automotive services in India with it’s excellence and proven expertise. Our technicians are highly skilled and equipped to handle luxury and premium cars, ensuring top-notch service and expertise. 
            </p>
            <p>
            We prioritize personalized experience for all our customers, coupled with meticulous attention to detail, to meet the unique car care needs of our clients. By combining cutting-edge technology with sustainable practices, we deliver unparalleled care for your vehicle, while setting new standards for environmental responsibility in the automotive industry. 
            </p>
            <p>
            Our passion for cars, coupled with our expertise and commitment to innovation, drives us to exceed expectations of our customers. The transparency in our service process sets us apart from other players in the market.
            </p>
            <p>
            Wrenchit is led by visionary leaders with deep expertise and 20+ years of experience in the luxury automotive industry. Our founders feature extensive experience from prestigious brands like Mercedes-Benz, BMW, Audi, and Jaguar Land Rover, and are committed to excellence and innovation. They ensure that Wrenchit delivers superior service at exceptional value to every customer.
            </p>
            <div className="row">
              <div className="col-md-6">
             <Link to="/repair-services">
             <ServiceItem
             alt="repair-services"
              icon={essential}
              title="Repair Services"
            />
             </Link>
              </div>
              <div className="col-md-6">
                <Link to="/master-dent-and-paint-solutions">
                <ServiceItem
              icon={master}
              alt="master-dent-and-paint"
              title="Master Dent & Paint "
            />
                </Link>
             
              </div>
              <div className="col-md-6">
                <Link to="/glamourous-details">
                <ServiceItem
              icon={glamour}
              alt="glamourous-detailing"
              title="Glamourous Detailing"
            />
                </Link>
              
                </div>
                <div className="col-md-6">
               <Link to="/periodic-maintenance">
               <ServiceItem
              icon={paint}
              alt="periodic-maintenance"
              title="Periodic Maintenance"
            />
               </Link>
                </div>
            <div className="col-md-6">
          <Link to="ceramic-coating">
          <ServiceItem
              icon={ceramic}
              alt="ceramic-coating"
              title="Ceramic Coating"
            />
          </Link>
            </div>
            <div className="col-md-6">
           <Link to="ppf-services">
           <ServiceItem
              icon={periodic}
              alt="ppf-services"
              title="PPF Services
"            />
           </Link>
            </div>
            </div>
            <div className="d-flex align-item-center gap-4 flex-wrap button-sect pt-4">
              <div
                className="button-home my-0"
                style={{ width: "auto!important", height: "fit-content" }}
              >
                <a className="btn-getstarted" href="/about">More About Us</a>
              </div>
              <div>
                <p className="mb-0" style={{ fontSize: "12px" , fontStyle : "italic", paddingBottom:"2px" }}>
                We’re Just One Call Away!{" "}
                </p>
                <span style={{ fontWeight: 600, fontSize: "18px" }}>
                +91 9096410050
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceDetails;
