import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { HelmetProvider } from 'react-helmet-async';  // Import HelmetProvider
import HomePage from "./Pages/HomePage";
import About from "./Pages/About";
import Services from "./Pages/Services";
import PrecisionAsthetic from "./Pages/PrecisionAsthetic";
import AdvancedProtection from "./Pages/AdvancedProtection";
import CeramicCoating from "./Pages/CeramicCoating";
import GlamourousDetails from "./Pages/GlamourousDetails";
import Contact from "./Pages/Contact";
import Layout from "./Layout/Layout";
import MasterDentPaintSolutions from "./Pages/Posts/MasterDentPaintSolutions";
import PeriodicMaintenance from "./Pages/Posts/PeriodicMaintenance";
import PPFServices from "./Pages/PPFServices";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ScrollToTop from "./Components/ScrollToTop";
import EssentailServices from './Pages/RepairServices.'
import InsuranceClaim from "./Pages/InsuranceClaim";
import Thankyou from "./Pages/Thankyou";
import PageNotFound from "./Components/PageNotFound";

function App() {
  return (
    <HelmetProvider>  {/* Wrap the entire application */}
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="about" element={<About />} />
            <Route path="services" element={<Services />} />
            <Route path="glamourous-details" element={<GlamourousDetails />} />
            <Route path="repair-services" element={<EssentailServices />} />
            <Route path="ceramic-coating" element={<CeramicCoating />} />
            <Route path="AdvancedProtection" element={<AdvancedProtection />} />
            <Route path="contact" element={<Contact />} />
            <Route path="ppf-services" element={<PPFServices />} />
            <Route path="master-dent-and-paint-solutions" element={<MasterDentPaintSolutions />} />
            <Route path="periodic-maintenance" element={<PeriodicMaintenance />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="insurance-claim" element={<InsuranceClaim />} />
            <Route path="thankyou" element={<Thankyou />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
    </HelmetProvider>
  );
}

export default App;
