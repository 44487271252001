import React from 'react'

function ServiceItem({ icon, title, description ,alt }) {
  return (
    <div className="service-item d-flex align-items-center">
    <div className="icon flex-shrink-0">
      <img src={icon} alt={alt} width={30} />
    </div>
    <div>
      <h4 className="title">{title}</h4>
    </div>
  </div>
  )
}

export default ServiceItem
