// src/components/SpecialOffers.jsx

import React from 'react';
import specialOffersImg from '../../assets/images/2.png';
import { Link } from 'react-router-dom';

const SpecialOffers = () => {
  return (
    <section id="stats" className="stats section discount-offers">
      <img src={specialOffersImg} alt="" data-aos="fade-in" />
      <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="">
            <div className="stats-item h-20 text-center" >
              <h5>Premium Services at Doorstep</h5>
              <h4>Hassle-Free Workflow, Convenient Pricing! </h4>
              <p>Enjoy convenient doorstep services tailored for your luxury vehicle. Schedule your appointment effortlessly today!</p>
              <br />
              <Link className="btn-getstarted f-right" to="https://calendly.com/inquiries-wrenchit/15-minutes-call" target="_blank" style={{ marginTop: '4px' }}>Book Appointment</Link>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SpecialOffers;
