import React from 'react'
import PageTitle from '../Components/policy/PageTitle'
import { Link } from 'react-router-dom'

function Privacy() {
  return (
    <div>
        <main className='main'>
      <PageTitle />
      <div className='container my-5'>
        <p>
        Your privacy is important to us at Wrenchit. We value the trust you place in us and are committed to protecting and safeguarding any personal data you provide. This document describes how we use and process your personal data and how we use cookies. It also explains how you can contact us if you have questions about your personal data or about cookies.
        </p>
        <p>
        Wrenchit offers online automotive services through its websites, mobile apps, and other online platforms such as partners’ websites and social media. The information provided applies to all these platforms. Please review this policy regularly to stay updated on any changes. If you disagree with this Privacy and Cookies Policy, please discontinue using our services.
        </p>
        <h4>
        Privacy
        </h4>
        <h6>
       <b>
       What Kind of Personal Information Does Wrenchit Use?
       </b>
        </h6>
        <p>
        When you book an appointment, we ask for your name, email address, and vehicle information. You might also be asked for your home address, telephone number, and additional data such as license and insurance details.
        </p>
        <p>
        To manage your appointments and purchase history more easily, you can open a user account. This allows you to save your personal settings, review previous bookings, and manage future reservations.
        </p>
        <p>
        When you visit our websites, even if you do not book an appointment, we may collect certain information, such as your IP address, the browser you are using, your computer’s operating system, application version, language settings, and the pages shown to you. If you are using a mobile device, we might also collect data that identifies your mobile device, device-specific settings and characteristics, and latitude/longitude details. When you book an appointment, our system registers the means and websites through which you booked an appointment or bought accessories.
        </p>
        <p>
        We may also receive information about you when you use certain social media services.
        </p>
        <h6 >
<b>        Why Does Wrenchit Collect, Use, and Share Your Personal Data?
</b>
        </h6>
        <p>
        - <b>	Book an Appointment:</b> We use your personal data to complete and administer your online appointment and forward your appointment details to the dealer where you made the booking
        </p>
        <p>
        -	<b>Customer Service:</b> Sharing your details with our global customer service staff allows us to respond to your needs, including helping you find an appropriate dealer and responding to questions about your appointment or accessories.
        </p>
        <p>
        -	<b>Guest Reviews:</b> We may use your contact information to invite you by email to write a guest review after your experience. This can help other users choose the dealer that suits them best.
        </p>
        <p>
        -	<b>Account Administration:</b> We use the information you provide to manage your user account, allowing you to manage bookings, take advantage of special offers, make future appointments more easily, and manage your personal settings.
        </p>
        <p>
        -	<b>Marketing Activities:</b>We use your information for marketing activities as permitted by law. For example:
        </p>
        <p>
        -	Sending you news of similar products and services when you book an appointment or set up a user account.<br/>
-	 Sending you newsletters with your consent.<br/>
-	Showing individualized offers based on the information you share with us on our website, in mobile apps, or on third-party websites, including social media sites.<br/>
-	Contacting you by phone if we believe a particular offer may interest you.

        </p>
        <p>
        -	<b>Other Communications:</b> We may contact you by email, post, phone, or text depending on the information you share with us for various reasons, such as handling requests, reminding you about unfinished appointments, sending questionnaires, or informing you about your appointments or accessories.
        </p>
        <p>
        -<b>	Market Research:</b> We may ask you to participate in market research. Any additional personal details you provide will be used only with your consent.
        </p>
        <p>
        -	<b>Fraud Detection and Prevention:</b> We use personal data to detect and prevent fraud and other illegal or unwanted activities.
        </p>
        <p>
        -	<b>Improving Our Services:</b> We use personal data for analytical purposes to improve our services, enhance the user experience, and improve the functionality and quality of our online automotive services.
        </p>
        <h6>
          <b>How Does Wrenchit Use Social Media?</b>
          </h6>
          <p>
          We use social media to promote our services and partners’ properties. For example, we integrate social media plugins into the Wrenchit website, allowing you to share information on social media platforms. When you use social media to sign in to Wrenchit services, your social media provider can tell you how they use and process your data.
          </p>
          <h6>
         <b>
         How Does Wrenchit Share Your Data with Third Parties?
         </b>
          </h6>
          <ul>
            <li>
            The Appointment You Booked: To complete your appointment, we need to transfer relevant details to the dealer you booked. This may include your name, contact details, payment details, additional services, and preferences. This information may also be used to give you personalized offers related to the appointment or accessories.
            </li>
            <li>
            Third-Party Service Providers: We use service providers to process your personal data on our behalf for purposes such as sending marketing material or providing analytical support. These processors are bound by confidentiality clauses and are not allowed to use your personal data for any other purpose.
            </li>
            <li>
            Competent Authorities: We disclose personal data to law enforcement and other governmental authorities as required by law or as necessary for the prevention, detection, or prosecution of criminal acts and fraud.
            </li>
            <li>
            Business Partners: We work with business partners worldwide to distribute or advertise dealers and their services. When you make an appointment on a business partner’s website, your personal data may be shared with us and vice versa. Please read the privacy policies on these partners’ websites for more information.
            </li>
          </ul>
     

        <h6>
    <b>
    How Does Wrenchit Use Guest Reviews and Other Information You Share with Us?

    </b>
        </h6>
        <p>
        After your experience, you will be invited to submit a guest review, which can be displayed on our website, mobile apps, social media accounts, or business partner websites. If you find a review helpful, we aggregate this feedback to prioritize reviews.
        </p>
        <h4>
        Cookies
        </h4>
        <h6>
          <b>
          What is a Cookie?
          </b>
        </h6>
        <p>
        A cookie is a small amount of data placed in your browser or on your mobile device. This Privacy and Cookies Policy applies to cookies and similar technologies.
        </p>
        <h6>
          <b>
          Why Are Cookies Used?
          </b>
        </h6>
        <p>
        Cookies allow you to be recognized as the same user across pages of a website and remember your choices, such as language preferences, currency, and search criteria. They ensure you are recognized when you return to a website.
        </p>
        <h6>
          <b>
          Who Has Access to Wrenchit Cookie Data?
          </b>
        </h6>
        <p>
        Only Wrenchit has access to Wrenchit cookies. Cookies placed by third parties can be accessed by those third parties.
        </p>
        <p>
        If you have any questions about your personal data or about cookies, please <Link to="contact">
        contact us
        </Link>
        </p>
      </div>
    </main>
    </div>
  )
}

export default Privacy