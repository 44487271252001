import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Picker from './DateAndTime/Picker';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const AppointmentForm = ({formId}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: 'default',
    brand: 'default',
    address: '',
    appointmentDate: '',
    message: '',
    formId:formId
  });

  const [responseMessage, setResponseMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData(prevData => ({
      ...prevData,
      appointmentDate: date ? date.toISOString() : '',
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('https://wrenchit.co.in/appointment.php', formData)
      .then((response) => {
        // setResponseMessage('Thank you! Your message has been sent.');
        console.log('Form ID:', formData.formId);
        navigate('/thankyou'); // Redirect to the Thank You page
        setFormData({
          name: '',
          email: '',
          phone: '',
          service: 'default',
          brand: 'default',
          address: '',
          appointmentDate: '',
          message: '',
          formId: formId, // Reset formId after submission
        });
      })
      .catch((error) => {
        console.error('There was an error!', error);
        setResponseMessage('An error occurred. Please try again later.');
      });
  };

  return (
    <div className="service-box contact-form mt-0">
      <h4>Make An Appointment</h4>
      <p style={{ lineHeight: '22px', marginTop: '10px' }}>
        Fill out the form, and we will note down your appointment while delivering the ease of pick-up and drop from your doorstep.
      </p>
      <div className="appoint-form">
        <Form onSubmit={handleSubmit} data-aos="fade-up" data-aos-delay="200">
          <Row className="gy-4">
            <Col md={12}>
              <Form.Group controlId="formName">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="formEmail">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="formPhone">
                <Form.Control
                  type="number"
                  name="phone"
                  placeholder="Enter Your Phone Number"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Row>
                <Col>
                  <Picker onDateChange={handleDateChange} />
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <Form.Group controlId="formService">
                <Form.Select
                  name="service"
                  value={formData.service}
                  onChange={handleChange}
                  style={{
                    color: formData.service === 'default' ? '#bdbdbd' : '#ffffff',
                    backgroundColor: formData.service === 'default' ? '#0f0f0f' : '#000000',
                  }}
                  required
                >
                  <option value="default" disabled>Select Services</option>
                  <option>Repair Services</option>
                  <option>Glamorous Detailing</option>
                  <option>Ceramic Coating</option>
                  <option>PPF Services</option>
                  <option>Master Dent & Paint Solutions</option>
                  <option>Periodic Maintenance</option>
                  <option>Insurance Claim</option>
                  <option>Others</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="formBrand">
                <Form.Select
                  name="brand"
                  value={formData.brand}
                  onChange={handleChange}
                  style={{
                    color: formData.brand === 'default' ? '#bdbdbd' : '#ffffff',
                    backgroundColor: formData.brand === 'default' ? '#0f0f0f' : '#000000',
                  }}
                  required
                >
                  <option value="default" disabled>Select Brand</option>
                  <option>Bentley</option>
                  <option>Lamborghini</option>
                  <option>Ferrari</option>
                  <option>Jaguar</option>
                  <option>Volvo</option>
                  <option>Rolls Royce</option>
                  <option>Range Rover</option>
                  <option>Mercedes-Benz</option>
                  <option>Audi</option>
                  <option>Mini</option>
                  <option>BMW</option>
                  <option>Maybach</option>
                  <option>Lexus</option>
                  <option>Maserati</option>
                  <option>Aston Martin</option>
                  <option>Land Rover</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group controlId="formMessage">
                <Form.Control
                  as="textarea"
                  name="message"
                  rows={3}
                  placeholder="Message"
                  required
                  value={formData.message}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={12} className="text-center">
              <button type="submit" className='btn-appoitment'>Make Appointment</button>
            </Col>
          </Row>
        </Form>
        {responseMessage && <p>{responseMessage}</p>}
      </div>
    </div>
  );
};

export default AppointmentForm;
