import React from 'react'
import PageTitle from '../Components/contact/PageTitle'
import ContactSection from '../Components/contact/ContactSection'
import { Helmet } from 'react-helmet-async'
const Contact = () => {
  return (
    <div>
       <Helmet>
        <title>Contact Wrenchit | Top Car Repair and Maintenance Services in Pune</title>
        <meta
          name="description"
          content="Get in touch with Wrenchit for top-tier car repair and maintenance services in Pune. Our team offers expert support, inquiries, and appointments for all your vehicle needs."
        />
        <link rel="canonical" href="https://wrenchit.co.in/contact" />

        "<meta property="og:title" content="Contact Wrenchit | Top Car Repair and Maintenance Services in Pune" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="https://wrenchit.co.in/contact" />
        <meta property="og:description" content="Get in touch with Wrenchit for top-tier car repair and maintenance services in Pune. Our team offers expert support, inquiries, and appointments for all your vehicle needs." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://wrenchit.co.in/logo-wrenchit.png" />
      </Helmet>
         <main className='main'>
      <PageTitle />
      <ContactSection  />
    </main>
    </div>
  )
}

export default Contact