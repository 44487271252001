import React from 'react'
import Banner from '../Components/Banner'
import ServiceDetails from '../Components/HomeCom/ServiceDetails'
import ServicesProvider from '../Components/HomeCom/ServicesProvider'
import WhyChooseUs from '../Components/HomeCom/WhyChooseUs'
import Pricing from '../Components/HomeCom/Pricing'
import MakeAnAppointment from '../Components/HomeCom/MakeAnAppointment'
import CallToActionBar from '../Components/HomeCom/CallToActionBar'
import PortfolioSection from '../Components/HomeCom/PortfolioSection'
import Testimonials from '../Components/HomeCom/Testimonials'
import BlogPosts from '../Components/HomeCom/BlogPosts'
import { Helmet } from 'react-helmet-async';

function HomePage() {
  return (

    <div>
      <Helmet>
        <title>Best Luxury Car Repair Services in Pune | Wrenchit</title>
        <meta name="description" content="Discover Wrenchit, premier destination for luxury car repair and maintenance services in Pune. Our expert technicians deliver unrivaled quality and excellence." />
        <link rel="canonical" href="https://wrenchit.co.in/" />
        <meta property="og:title" content="Best Luxury Car Repair Services in Pune | Wrenchit" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="https://wrenchit.co.in/" />
        <meta property="og:description" content="Discover Wrenchit, premier destination for luxury car repair and maintenance services in Pune. Our expert technicians deliver unrivaled quality and excellence." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://wrenchit.co.in/logo-wrenchit.png" />
      </Helmet>
      <Banner />
      <ServiceDetails />
      <ServicesProvider />
      <WhyChooseUs />
      {/* <Pricing/> */}
      <MakeAnAppointment />
      {/* <CallToActionBar/> */}
      {/* <PortfolioSection/> */}
      <Testimonials />
      {/* <BlogPosts/> */}
    </div>
  )
}

export default HomePage
