import React from 'react';

const BlogPostsSection = () => {
  return (
    <section id="blog-posts" className="blog-posts section">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-4">
            <article>
              <div className="post-img">
                <img src="assets/img/3.png" alt="" className="img-fluid" />
              </div>
            </article>
          </div>
          {/* End post list item */}

          <div className="col-lg-4">
            <article>
              <div className="post-img">
                <img src="assets/img/4.png" alt="" className="img-fluid" />
              </div>
            </article>
          </div>
          {/* End post list item */}

          <div className="col-lg-4">
            <article>
              <div className="post-img">
                <img src="assets/img/5.png" alt="" className="img-fluid" />
              </div>
            </article>
          </div>
          {/* End post list item */}
        </div>
      </div>
    </section>
  );
};

export default BlogPostsSection;
